import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How i'd make $100k in a week right now`}</h1>
    <p>{`I was `}<em parentName="p">{`planning`}</em>{` to tell you about what we did today...but I have a better idea.`}</p>
    <p>{`I saw something posted in the ＃Random channel of our All Access Pass slack that caught my eye.`}</p>
    <p>{`A great idea.`}</p>
    <p>{`Well. not that great. But a fun idea that `}<strong parentName="p">{`I would totally do`}</strong>{` if I was 22 years old right now.`}</p>
    <p>{`See - the election just happened, and emotions are running high in the United States.`}</p>
    <p>{`Half the country is `}<strong parentName="p">{`so happy`}</strong>{` Trump is gone, and we have a new president + first female vice president.`}</p>
    <p>{`Half the country is `}<strong parentName="p">{`pissed`}</strong>{` that Trump is a goner. The election was rigged! Fake News strikes again! F*ck you left wingers!`}</p>
    <p>{`People are very emotional.`}</p>
    <p>{`I’m not here to tell you who’s right and who’s wrong, or how you should feel...but I will say - this is a great opportunity for a pop-up business.`}</p>
    <p>{`Emotion = Opportunity.`}</p>
    <p>{`If you create content that triggers an emotional reaction, watch the numbers soar. For example, `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/m2h7h5hokekqkrcm/aHR0cHM6Ly90d2l0dGVyLmNvbS9TaGFhblZQL3N0YXR1cy8xMzI1MjUwNTc2NzkyMjM2MDM0"
      }}>{`look at this tweet`}</a>{` I sent out over the weekend. This tweet has over 7,500+ likes and was shared over 1,500 times...which makes it my most popular tweet ever.`}</p>
    <p>{`Is it some genius business idea? Some words of wisdom? A great photo?`}</p>
    <p>{`No, it’s a tweet that taps into people’s emotions. In this case, the “F`}{`*`}{`ck Facebook” and “F`}{`*`}{`ck Twitter” sentiment that conservatives have because they feel they are being censored by big tech.`}</p>
    <p>{`Is it true? Who knows. There’s a lot of nuance to the story.`}</p>
    <p>{`But perception of reality `}<strong parentName="p">{`is`}</strong>{` reality. So for these people, it’s true.`}</p>
    <p>{`OK - so what’s the business opportunity?`}</p>
    <p>{`A few weeks ago, when Ben and I were looking at buying that collectibles collection..we learned that the collector also owned tons of vintage newspaper front pages from key events.`}</p>
    <p>{`For example, when Barry Bonds broke the homerun record, the collector would go buy 1,000 copies of the San Francisco Chronicle front page.`}</p>
    <p>{`Those papers are normally $0.35, but those same papers can sell for `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/dpheh0hqz5zo4zim/aHR0cHM6Ly93d3cuZWJheS5jb20vaXRtL1NGLUNocm9uaWNsZS1OZXdzcGFwZXJzLUJhcnJ5LUJvbmRzLUhvbWUtUnVuLUZlYXRzLS8yMjMxODgyMjI3NzU="
      }}>{`$18-25`}</a>{` on eBay right now. That means it costs you $350 to buy, and you can make a smooth $15-20k off them over time.`}</p>
    <p>{`Not bad for a side hustle!`}</p>
    <p>{`So if I was a 22 year old kid with nothing but time on my hands today… I would do a 1-week experiment - selling products against these emotions.`}</p>
    <p><strong parentName="p">{`Here’s how I’d try to make $100k in a week:`}</strong></p>
    <ol>
      <li parentName="ol">{`I’d buy “back copies” of the NYTimes edition with Biden + Harris winning the 2020 election. You can buy 50 copies each, so I’d need to get 20 friends to help me out. Looks like these cost $5.75 each, so this would cost about ~$6k to buy 1000 copies`}</li>
      <li parentName="ol">{`Next, I would frame & market these as “limited edition” collector’s items. And sell them for $100 each ($100k potential revenue, at 94% gross margin) for people who want it as art`}</li>
      <li parentName="ol">{`You could run facebook ads (but that would eat into profits), so I’d start by trying to get viral PR on sites like Buzzfeed and get my story run in newsletters like The Skimm, The Hustle & Morning Brew about how these are selling for $100 a pop!`}</li>
      <li parentName="ol">{`I’d also try to find facebook groups (or go to local celebrations and setup a bootleg table) to sell into, and list it on eBay as well.`}</li>
      <li parentName="ol">{`I’d hang on to 10-20 copies, because they might go up in value as years go by.`}</li>
    </ol>
    <p>{`Bing bang boom. $100k in a week! This would work best if you knew you wanted to do this ahead of time (either way, the results of the election were going to be iconic), and could find cheaper ways to source the papers or get your storefront ready beforehand.`}</p>
    <p>{`The other opportunity (a bigger one imo) would be to sell “Rage Merch” to trump supporters.`}</p>
    <p>{`Think hats, bumper stickers, flags etc.. that are around:`}</p>
    <ul>
      <li parentName="ul">{`Rigged election`}</li>
      <li parentName="ul">{`Fraud 2020`}</li>
      <li parentName="ul">{`Stop The Count`}</li>
      <li parentName="ul">{`TRUMP WON`}</li>
      <li parentName="ul">{`Etc..`}</li>
    </ul>
    <p>{`Anything that I could print on demand using teespring, printful etc.. so I don’t have to worry about fulfillment.`}</p>
    <p>{`I would sell these via ghetto display ads on outbrain/taboola and try to get placement on right wing sites like Breitbart, Drudgreport, etc… or send to conservative influencers like Tami Lahren, Steven Crowley etc..`}</p>
    <p>{`I’d also try to get as many PR hits as I can on left/liberal sites as I can, because people will share the hell outta this either supporting or mocking it.`}</p>
    <p>{`I’d call the brand REAL AMERICA or AMERICAN TRUTH or something like that.`}</p>
    <p>{`Look at the brand `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/e0hph7hk949lppt8/aHR0cHM6Ly93d3cuZ3J1bnRzdHlsZS5jb20v"
      }}>{`Grunt Style`}</a>{`. These guys do `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/7qh7h8h0vzv6k6bz/aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g_dj1zbFBvY0dFN3hiNA=="
      }}>{`over $100m+ a year`}</a>{` playing into the conservative american “duck hunt wasn’t just a game, it’s a way of life” vibe.`}</p>
    <p>{`Maybe 10% of profits go towards funding investigation of the rigged election. And maybe the investigation is conducted by your pet lizard and you pocket it. Idk, that part’s up to you.`}</p>
    <p>{`If you think all of this is crazy - go `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/owhkhqh43o36kncv/aHR0cHM6Ly9wYW5kby5jb20vMjAxMy8wMS8xMC9icmlhbi1jaGVza3ktaS1saXZlZC1vbi1jYXBuLW1jY2FpbnMtYW5kLW9iYW1hLW9zLWdvdC1haXJibmItb3V0LW9mLWRlYnQv"
      }}>{`read the story of how AirBnB stayed alive early on by selling Obama O’s and Captain McCain Crunch`}</a>{` limited edition cereal boxes at the political conventions when they were out of cash.`}</p>
    <p>{`These aren’t meant to be great businesses - in the same way that a tinder date isn’t always meant to be your wife. This is like a business fling. No shame in that!`}</p>
    <p>{`-shaan`}</p>
    <p>{`P.S. `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/92u0v2g5gwinh0g44za9/z2hghnho9w9k4rup/aHR0cHM6Ly90d2l0dGVyLmNvbS9hbGV4a2Voci9zdGF0dXMvMTMyNTk2NzQyOTIxODk1OTM2MA=="
      }}>{`here’s another one we just saw on twitter`}</a>{`, this guy is trying to send 1m post cards to trump...at $2 a piece with $0.40 of shipping + $0.20 to print, if he can sell 1m, he’ll net about $1.4m...not bad for a quick hustle`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      